document.addEventListener("turbolinks:load", () => {
  if ($("#summary_report").length > 0){
    // Online Registration Distribution //
    var registration_by_date = $.map($('#report-attr').data('registration_by_date'), function (x) {
      return { name: x[0], y: parseInt(x[1]) }
    })
    registration_by_date.push({
      name: 'Total',
      isSum: true,
      color: Highcharts.getOptions().colors[1]
    });

    Highcharts.chart('report_registrations', {
      chart: { type: 'waterfall' },
      title: { text: 'Breakdown of online registration by Date' },
      xAxis: { type: 'category' },
      yAxis: { title: { text: 'registrations' } },
      legend: { enabled: false },
      tooltip: { pointFormat: '<b>{point.y}</b> registrations' },
      credits: { enabled: false },
      series: [{
        upColor: '#7bb5ec',
        color: 'Highcharts.getOptions().colors[3]',
        data: registration_by_date,
        dataLabels: {
          enabled: true,
            formatter: function () {
              return Highcharts.numberFormat(this.y, 0, ',');
            },
          style: {
            fontWeight: 'bold'
          }
        },
        pointPadding: 0
      }]
    });
    
    // Attendance Summary Bar chart //
    Highcharts.chart('attendance_view', {
      chart: {
        type: 'bar'
      },
      title: {
        text: 'Live Webcast Attendance Summary'
      },
      subtitle: {
        text: ''
      },
      xAxis: {
        categories: ['Working Group Participants', 'Shareholders'],
        title: {
          text: null
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: '',
          align: 'high'
        },
        labels: {
          overflow: 'justify'
        }
      },
      tooltip: {
        valueSuffix: ' millions'
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'top',
        x: -40,
        y: 220,
        floating: true,
        borderWidth: 1,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        shadow: true
      },
      credits: {
        enabled: false
      },
      series: [
        {
          name: 'Total number of email ids enabled for sign-in to live webcast',
          color: 'blue',
          data: $('#report-attr').data('total_group_number_email_records').replace(" ", "").split(",").map(Number)
        },
        {
          name: 'Number of enabled email ids with sign-in records for live webcast',
          color: 'green',
          data: $('#report-attr').data('group_number_email_with_sign_in_records').replace(" ", "").split(",").map(Number)
        },
        {
          name: 'Number of enabled email ids not present for the live webcast',
          color: 'red',
          data: $('#report-attr').data('group_number_email_no_sign_in_records').replace(" ", "").split(",").map(Number)
        }
      ]
    });

    // Attendance Summary bar chart Pie chart //
    // Make monochrome colors
    var pieColors = (function () {
      var colors = [],
        base = Highcharts.getOptions().colors[0],
        i;

      for (i = 0; i < 10; i += 1) {
        // Start out with a darkened base color (negative brighten), and end
        // up with a much brighter color
        colors.push(Highcharts.color(base).brighten((i - 3) / 7).get());
      }
      return colors;
    }());

    // Build the chart
    Highcharts.chart('chart_div3', {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Live Webcast Overall Attendance Rate'
      },
      tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
        point: {
          valueSuffix: ''
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          colors: pieColors,
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.1f}% ',
            distance: -50,
            filter: {
              property: 'percentage',
              operator: '>',
              value: 4
            }
          }
        },
        series: {
          showInLegend: true,
          dataLabels: {
            enabled: false
          }
        }
      },
      series: [{
        name: 'Share',
        data: [
          {
            name: 'No of enabled email ids with sign-in records for live webcast',
            color: 'green',
            y: parseInt($('#report-attr').data('total_number_email_with_sign_in_records'))
          },
          {
            name: 'No of enabled email ids not present for the live webcast',
            color: 'red',
            y: parseInt($('#report-attr').data('total_number_email_no_sign_in_records'))
          },
        ]
      }],
      exporting: {
        fallbackToExportServer: false
      }
    });

    // Rate of Sign-in for Live Webcast //

    var rate_of_sign_in = $.map($('#report-attr').data('rate_of_sign_in'), function (x) {
      return { name: x[0], y: x[1] }
    });

    rate_of_sign_in.push({
      name: 'Total',
      isSum: true,
      color: Highcharts.getOptions().colors[1]
    });
    
    Highcharts.chart('chart_div4', {
      chart: { type: 'waterfall' },
      title: { text: 'Breakdown of Sign-in by 5-minutes Interval' },
      xAxis: { type: 'category' },
      yAxis: { title: { text: 'registrations' } },
      legend: { enabled: false },
      tooltip: { pointFormat: '<b>{point.y}</b> registrations' },
      credits: { enabled: false },
      series: [{
        upColor: Highcharts.getOptions().colors[2],
        color: Highcharts.getOptions().colors[3],
        data: rate_of_sign_in,
              dataLabels: {
                enabled: true,
                  formatter: function () {
                    return Highcharts.numberFormat(this.y, 0, ',');
                  },
                style: {
                  fontWeight: 'bold'
                }
              },
              pointPadding: 0
            }]
          });

  }
})