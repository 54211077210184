import axios from 'axios'

var csrfToken;
if (document.querySelector("meta[name=csrf-token]")) {
  csrfToken = document.querySelector("meta[name=csrf-token]").content
}
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

var OtpAuth;
var OtpTimerinterval;
var RetryTimerinterval;
if (typeof OtpAuth === 'undefined') OtpAuth = {};

const form = document.querySelector('#otpAuthForm')
const email = document.querySelector('#otpAuthEmail')
const password = document.querySelector('#otpAuthPassword')
const otpField = document.querySelector('#otpAuthOtp')
const otpSentText = document.querySelector('.otp-sent-text')
const note = document.querySelector('.note')
const forgotPasswordLink = document.querySelector('#forgot-password')
const helpContent = document.querySelector('#login_help_content')
const resendOTP = document.querySelector('#resendOTP')

OtpAuth = {
  timerState: false,
  retryTimerState: false,
  emailState: '',
  mobileState: '',
  passwordState: '',
  retriesState: 0,
  maxRetriesState: 0,
  init: () => {
    OtpAuth.resource = OtpAuth.getResourceType()
    email.onfocus = () => {
      OtpAuth.showSubmitButton()
      OtpAuth.clearError()
    }
    if (password) {
      password.onfocus = () => {
        OtpAuth.showSubmitButton()
        OtpAuth.clearError()
      }
    }
    otpField.onfocus = () => {
      OtpAuth.showSubmitButton()
    }
    OtpAuth.switchForm('email') // Set default view
    OtpAuth.submit() // Listen on form submit
    OtpAuth.resendOTP() // Listen resendOTP
  },

  getOTP: (formData) => {
    let dataObj = {
        method: 'POST',
        url: OtpAuth.formAction('/send_otp'),
        data: { [OtpAuth.resource]: formData }
    }

    OtpAuth.showBtnSpinner(document.querySelector("input[value='Get OTP to log in']"))
    OtpAuth.showBtnSpinner(resendOTP)

    axios(dataObj)
      .then(response => {
        OtpAuth.resetBtnSpinner(document.querySelector("input[value='Loading...']"))
        OtpAuth.resetBtnSpinner(resendOTP)

        if(response.status == 200 && response.data.status == 'ok' && response.data.email === OtpAuth.emailState.toLowerCase()) {
          let codeDate = new Date(response.data.time);
          let curDate = new Date(Date.now())
          let diff = codeDate.getTime() - curDate.getTime()
          // TODO: refactor
          if(response.data.retry_timeout) {
            let retryCodeDate = new Date(response.data.retry_timeout)
            let retryDiff = retryCodeDate.getTime() - curDate.getTime()
            OtpAuth.setRetryTimer(retryDiff/1000) // start retry timer
          }
          if(response.data.retry_by) {
            resendOTP.innerHTML = 'Resend OTP via ' + response.data.retry_by
          } else {
            resendOTP.innerHTML = ''
          }
          if(response.data.retry_by && !response.data.retry_timeout){
            resendOTP.style.display = 'block'
          }
          if(response.data.sent_via == 'email') {
            otpSentText.innerHTML = 'We have sent the OTP to your email '+ response.data.email +'.'
          } else {
            otpSentText.innerHTML = 'We have sent the OTP to your mobile number '+ response.data.mobile +'.'
          }

          OtpAuth.mobileState = response.data.mobile
          OtpAuth.switchForm('otp') // show OTP input
          OtpAuth.setTimer(diff/1000) // start timer
        } else if (response.status == 200 && response.data.status == 'fail_email'){
          OtpAuth.switchForm('email')
          OtpAuth.setError(response.data.error || 'No response from server', 10000)
        }else{
          OtpAuth.switchForm('email')
          OtpAuth.setError(response.data.error || 'No response from server')
        }
      })
      .catch(error => console.log('catch',error))
  },

  verifyOTP: (formData) => {
    let dataObj = {
        method: 'POST',
        url: OtpAuth.formAction('/sign_in'),
        data: { [OtpAuth.resource]: formData }
      }

    OtpAuth.showBtnSpinner(document.querySelector("input[value='Continue']"))

    axios(dataObj)
      .then(response => {
        OtpAuth.resetBtnSpinner(document.querySelector("input[value='Loading...']"))

        if(response.status == 200 && response.data.status == 'ok') {
          window.location.href = window.location.pathname + window.location.search;
        } else if (response.status == 200 && response.data.status == 'fail' && response.data.verifyed) {
          OtpAuth.setError(response.data.error)
          setInterval(function () { window.location.href = OtpAuth.formAction('/sign_in')}, 5000);
        }else{
          OtpAuth.setError(response.data.error || 'No response from server')
        }
      })
      .catch(error => console.error('catch',error))
  },

  setTimer: (duration) => {
    let timer = duration, minutes, seconds;
    let elem = document.querySelector('#timer')
    let elemContainer = document.querySelector('#timerContainer')

    if (OtpAuth.timerState === true) {
      clearInterval(OtpTimerinterval);
      OtpTimerinterval = null;
      elemContainer.style.display = 'none'
      elem.innerHTML = ''
    }

    if (!OtpTimerinterval) {
      elemContainer.style.display = 'block'
      OtpTimerinterval = setInterval(() => {
        OtpAuth.timerState = true
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        elem.textContent = minutes + ":" + seconds; // set time

        if (--timer < 0) {
          clearInterval(OtpTimerinterval)
          OtpTimerinterval = null;
          elemContainer.style.display = 'none'
          elem.innerHTML = '' // clear timer element
          window.location.href = OtpAuth.formAction('/sign_in')
        }
      }, 1000);
    }
  },

  setRetryTimer: (duration) => {
    let timer = duration;
    let elem = document.querySelector('#retryTimer')
    let elemContainer = document.querySelector('#retryTimerContainer')

    if (OtpAuth.retryTimerState === true) {
      clearInterval(RetryTimerinterval);
      RetryTimerinterval = null;
      elemContainer.style.display = 'none'
      elem.innerHTML = ''
    }

    if (!RetryTimerinterval) {
      elemContainer.style.display = 'block'
      resendOTP.style.display = 'none'
      RetryTimerinterval = setInterval(() => {
        OtpAuth.retryTimerState = true
        elem.textContent = parseInt(timer); // set time

        if (--timer < 0) {
          clearInterval(RetryTimerinterval)
          RetryTimerinterval = null;
          elemContainer.style.display = 'none'
          elem.innerHTML = '' // clear timer element
          resendOTP.style.display = 'block'
        }
      }, 1000);
    }

  },

  switchForm: (view) => {
    let inputs = form.elements
    if(view === 'email') {
      otpSentText.style.display = 'none' // hide header above otp input box
      otpField.style.display = 'none' // hide otp box
      email.style.display = 'block'
      if (password) {
        password.style.display = 'block'
        forgotPasswordLink.style.display = 'block'
      }
      inputs['commit'].className = inputs['commit'].className.replace(/btn\-success/g, "btn-primary")
      inputs['commit'].value = 'Get OTP to log in'
      inputs['commit'].dataset.disableWith = inputs['commit'].value
      note.style.display = 'none' // hide notes
      if (resendOTP) {
        resendOTP.style.display = 'none' // hide resendOTP
      }
      OtpAuth.timerState = false
      OtpAuth.retryTimerState = false
    }else if(view === 'otp') {
      otpField.style.display = 'block' // show otp box
      otpField.value = '' // clear otp field
      email.style.display = 'none'
      if (password) {
        password.style.display = 'none'
        forgotPasswordLink.style.display = 'none'
      }
      note.style.display = 'block' // show notes
      if (helpContent){
        helpContent.style.display = 'none' // hide helpContent
      }
      otpSentText.style.display = 'block' // show header above otp input box
      inputs['commit'].className = inputs['commit'].className.replace(/btn\-primary/g, "btn-success")
      inputs['commit'].value = 'Continue'
    }else{
      console.error('Something wrong with switchForm method, view not passed')
    }
  },

  showSubmitButton: () => {
    let inputs = form.elements
    inputs['commit'].disabled = false
  },

  validation: (field, type) => {
    let valid = true
    try {
      if(!field || field.length <= 0) {
        valid = false
        OtpAuth.setError('Please Fill out This Field')
        if(type == 'email'){
          OtpAuth.switchForm('email')
        }
      }else if(type == 'email') {
        let pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(pattern.test(String(field).toLowerCase()) === false) {
          valid = false
          OtpAuth.setError('Email should be valid')
          OtpAuth.switchForm('email')
        }
      }else if(type == 'otp') {
        let pattern = /^[0-9]{1,6}$/
        if(pattern.test(field) === false) {
          valid = false
          OtpAuth.setError('Code should be 6 digits long')
        }
      }
    } catch (e) {
      console.error(e)
      valid = false
    }
    return valid
  },

  setError: (msg, timeout = 5000) => {

    OtpAuth.createErrorElement(msg, 'alert-danger')

		window.setTimeout(function() {
      OtpAuth.clearError()
    }, timeout)

  },

  clearError: () => {
    let toRemove = document.querySelector('#notice')
    if(toRemove) {
      toRemove.parentNode.removeChild(toRemove);
    }
  },

  submit: () => {
    form.addEventListener("submit", (e) => {
      e.preventDefault()

      var formData = new FormData(form)

      if(OtpAuth.timerState === true) {
        if (password) {
          let otp = formData.get('otp')
          if(OtpAuth.validation(otp,'otp')) {
            OtpAuth.verifyOTP({ 'otp': otp, 'email': OtpAuth.emailState, 'password': OtpAuth.passwordState })
          }
        }else{
          let otp = formData.get('otp')
          if(OtpAuth.validation(otp,'otp')) {
            OtpAuth.verifyOTP({ 'otp': otp, 'email': OtpAuth.emailState })
          }
        }
      }else{
        OtpAuth.sendOTP(form);
      }
    }, false);

  },

  createErrorElement: (msg, type) => {
    var body = document.querySelector('body')
    var notice = document.createElement('div')
    var alert = document.createElement('div')
    var icon = document.createElement('i')
    var button = document.createElement('button')
    var span = document.createElement('span')
    var text = document.createElement('span')

    notice.style.display = 'block'
    notice.setAttribute('id', 'notice')
    alert.setAttribute('role', 'alert')
    alert.className = 'alert '+type+' alert-dismissible fade show'
    icon.className = 'fas fa-exclamation-triangle'
    button.setAttribute('type', 'button')
    button.setAttribute('data-dismiss', 'alert')
    button.setAttribute('area-label', 'Close')
    button.className = 'close'
    span.setAttribute('aria-hidden','true')
    span.innerHTML = '&times;'
    text.innerHTML = msg

    body.appendChild(notice)
    notice.appendChild(alert)
    alert.appendChild(icon)
    alert.appendChild(text)
    alert.appendChild(button)
    button.appendChild(span)
  },

  resendOTP: () =>{
    resendOTP.addEventListener("click", (e) => {
      e.preventDefault();
      OtpAuth.sendOTP(form);
    });
  },

  sendOTP: (form) =>{
    var formData = new FormData(form)

    if (password) {
      let email, password
      email = formData.get(OtpAuth.resource+'[email]')
      password = formData.get(OtpAuth.resource+'[password]')
      if(OtpAuth.validation(email,'email') && OtpAuth.validation(password,'password')) {
        OtpAuth.getOTP({ 'email': email, 'password': password })
        OtpAuth.emailState = email
        OtpAuth.passwordState = password
      }
    }else{
      let email = formData.get(OtpAuth.resource+'[email]')
      if(OtpAuth.validation(email,'email')) {
        OtpAuth.getOTP({ 'email': email })
        OtpAuth.emailState = email
      }
    }
  },

  showBtnSpinner: function (btnSelector) {
    if (!btnSelector) {
      return
    }

    if (btnSelector.type == 'submit') {
      let inputs = form.elements
      inputs['commit'].dataset.origValue = inputs['commit'].value
      inputs['commit'].value = "Loading..."
      inputs['commit'].dataset.disableWith = inputs['commit'].value
      inputs['commit'].disabled = true
    } else {
      let btnText = btnSelector.innerHTML
      btnSelector.disabled = true
      btnSelector.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true">' +
      '</span> Loading...' +
      "<div class='d-none btn-text'>" + btnText + "</div>"
    }
  },

  resetBtnSpinner: function (btnSelector) {
    if (!btnSelector) {
      return
    }

    if (btnSelector.type == 'submit') {
      let inputs = form.elements
      inputs['commit'].value = inputs['commit'].dataset.origValue
      inputs['commit'].dataset.origValue = ''
      inputs['commit'].dataset.disableWith = inputs['commit'].value
      inputs['commit'].disabled = false
    } else {
      let btnText = btnSelector.querySelector('.d-none.btn-text').innerHTML
      btnSelector.disabled = false
      btnSelector.innerHTML = btnText
    }
  },

  getResourceType: function(){
  if (form){
    let action = form.getAttribute('action').split('/')[1]
    return action == 'admins' ? 'admin' : 'user'
  }
  },

  formAction: function(path){
    return OtpAuth.getResourceType() == 'admin' ? '/admins' + path : path
}
}

document.addEventListener("turbolinks:load", () => {
  if(form) {
    OtpAuth.init()
  }
})
