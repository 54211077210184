require("packs/add_calendar_event")
document.addEventListener("turbolinks:load", () => {
  // Registration End Timer
  if ( $("#timer_end_date").length > 0){
    var timer_end_date = $("#timer_end_date").text();
    const second = 1000,
          minute = second * 60,
          hour = minute * 60,
          day = hour * 24;

    let countDown = new Date(timer_end_date).getTime(),
        x = setInterval(function() {

          let now = new Date().getTime(),
              distance = countDown - now;

          document.getElementById('countdown_registration_days').innerText = Math.floor(distance / (day)),
          document.getElementById('countdown_registration_hours').innerText = Math.floor((distance % (day)) / (hour)),
          document.getElementById('countdown_registration_minutes').innerText = Math.floor((distance % (hour)) / (minute)),
          document.getElementById('countdown_registration_seconds').innerText = Math.floor((distance % (minute)) / second);

          //do something later when date is reached
          if (distance <= 0) {
            clearInterval(x);
            document.getElementById('countdown_registration_days').innerText = 0,
            document.getElementById('countdown_registration_hours').innerText = 0,
            document.getElementById('countdown_registration_minutes').innerText = 0,
            document.getElementById('countdown_registration_seconds').innerText = 0;
          }
        }, second);

    // Add Event to Calendar
    if($(".add_calendar").length > 0 ){
      let e_title = $('#e_title').text(),
          e_desc = $('#e_desc').text(),
          e_venue = $('#e_venue').text(),
          e_start = $('#e_start').text(),
          e_end = $('#e_end').text();
          e_ics = $('#ics_url').text();
      $(".ace_btn").addcalevent({
        'data': {
          "title": e_title,
          "desc": e_desc,
          "location": e_venue,
          "time":{
            "start": e_start,
            "end": e_end,
            "zone":"+08:00"
          },
        },
        'ics': e_ics
      });
    }
  }
})