require("packs/jquery.magnific-popup")
require("packs/smk-accordion")
// require("packs/pigeonhole")
// require("packs/webcast_QA_01")
// require("packs/webcast_QA_02")

document.addEventListener("turbolinks:load", () => {
  if($("body.webcast").length > 0 || $("body.preview_webcast").length > 0) {
    $('.open-popup-link').magnificPopup({
      type:'inline',
      midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
    });

    $(".accordion_bod").smk_Accordion({
      closeAble: true, //boolean
    });
  }

  if ($("iframe").length > 0) {
    $("iframe").on('load', function() {
      if ($('iframe').attr('src') && $('iframe').attr('src').includes("/rails/mailers")) {
        $(this).height( $(this).contents().find("body").find('iframe').contents().find("body").height() + 150 );
      }
    });
  }

  if ($("#si-player").length > 0){
    var PLAYBACK_URL = $("#event-attr").data('link');
    var player;
    (function play() {
      registerIVSTech(videojs);
      registerIVSQualityPlugin(videojs);
      player = videojs('si-player', {
        techOrder: ["AmazonIVS"],
        controlBar: {
          playToggle: {
            replay: false
          },
          pictureInPictureToggle: false
        }
      });
    })();

    function play_stream(){
      $.ajax({
        url: PLAYBACK_URL,
        type: 'GET',
        timeout: 9000,
        success: function () {
          player.enableIVSQualityPlugin();
          player.volume(0.5);
          player.src(PLAYBACK_URL);
        },
        error: function () {
          setTimeout(play_stream, 10000);
        },
        fail: function () {
          setTimeout(play_stream, 10000);
        },
      })
    }
    if (PLAYBACK_URL) {
      play_stream()
    }
  }
  
  if (window.location.pathname.indexOf("/webcast") >= 0) {

    if ($("#event-attr").data('request_consent') == true && $("#event-attr").data('consent') != true ){
      setTimeout(function () {
        show_consent_form()
      }, 5000);
    }
    function show_consent_form() {
      var event_slug = window.location.pathname.split('/')[1];
      var consent_form_value = event_slug + "=" + "shown_consent_form"
      if ( document.cookie.indexOf(event_slug) == -1 && document.cookie.indexOf(consent_form_value) == -1 ){
        document.getElementById("consent").addEventListener("click", consent_form);
        $("#consentModal").modal('show');
        createCookie(event_slug, "shown_consent_form", 1);
      }
    }
    function consent_form(){
      var event_slug = window.location.pathname.split('/')[1];
      $.ajax({
        url: '/downloads/consent_request',
        type: "POST",
        data: { event_slug, status: true },
        success: function () { $("#consentModal").modal('hide'); }
      });
    }

    function createCookie(name, value, days) {
      var date, expires;
      if (days) {
        date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        date.setHours(0);
        date.setMinutes(0);
        expires = "; expires=" + date.toUTCString();
      } else {
        expires = "";
      }
      document.cookie = name + "=" + value + expires + "; path=/";
    }

  }

  // Fix webcast footer
  function adjustFooterPosition(){
    if( $(".webcast > .container").innerHeight() + $("#webcast-footer").innerHeight() >= $("body").innerHeight() ){
      $("#webcast-footer").css({"position" : "static", "margin-top" : "35px" });
    }else{
      $("#webcast-footer").css({"position" : "fixed", "margin-top" : "0" });
    }
  }
  adjustFooterPosition();
  $(window).on("resize", function(){
    adjustFooterPosition();
  });

})