import axios from 'axios'
import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/js/utils'

var csrfToken;
if (document.querySelector("meta[name=csrf-token]")) {
  csrfToken = document.querySelector("meta[name=csrf-token]").content
}
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken

var ValidateRegistration;
var OtpTimerinterval;
var RetryTimerinterval;
if (typeof ValidateRegistration === 'undefined') ValidateRegistration = {};

const RVForm = document.querySelector('#registration-form')
const validateEmailButton = document.querySelector('#validate_email_button')
const validateMobileButton = document.querySelector('#validate_mobile_button')
const resendValidateEmailButton = document.querySelector('#resend_validate_email')
const resendValidateMobileButton = document.querySelector('#resend_validate_mobile')
const verifyEmailButton = document.querySelector('#verify_email_otp_button')
const verifyMobileButton = document.querySelector('#verify_mobile_otp_button')
const continueButton = document.querySelector('#continue_registration')
const changeEmailLink = document.querySelector('#change_email')
const changeMobileLink = document.querySelector('#change_mobile')
const updateEmailLink = document.querySelector('#update_email')
const updateMobileLink = document.querySelector('#update_mobile')

ValidateRegistration = {
  timerState: false,
  retryTimerState: false,
  currentEmail: '',
  currentMobile: '',
  validateLabel: '',
  inputContainerSelector: '',

  /**
   * Initialize
   *
   * @return {void}
   */
  init: function () {

    let value = document.querySelector('#registration_mobile').value
    ValidateRegistration.resetMobileNumber(value)

    /**
     * On verify OTP click button
     */
    verifyEmailButton.addEventListener('click', function (e) {
      e.preventDefault()
      if (!$(this).find('button').prop('disabled')) {
        ValidateRegistration.verifyEmailOTP()
      }
    })

    /**
     * On verify OTP click button
     */
    if (verifyMobileButton) {
      verifyMobileButton.addEventListener('click', function (e) {
        e.preventDefault()
        if (!$(this).find('button').prop('disabled')) {
          ValidateRegistration.verifyMobileOTP()
        }
      })
    }

    /**
     * On resend button click
     */
    resendValidateEmailButton.addEventListener('click', function (e) {
      e.preventDefault()
      if (!$(this).prop('disabled')) {
        ValidateRegistration.sendEmailData()
      }
    })

    /**
     * On resend button click
     */
    if (resendValidateMobileButton) {
      resendValidateMobileButton.addEventListener('click', function (e) {
        e.preventDefault()
        if (!$(this).prop('disabled')) {
          ValidateRegistration.sendMobileData()
        }
      })
    }

    /**
     * On validate button click, to validate email
     */
    validateEmailButton.addEventListener('click', function (e) {
      e.preventDefault()
      if (!$(this).find('button').prop('disabled')) {
        ValidateRegistration.sendEmailData()
      }
    })

    /**
     * On validate button click, to validate email
     */
    if (validateMobileButton) {
      validateMobileButton.addEventListener('click', function (e) {
        e.preventDefault()
        if (!$(this).find('button').prop('disabled')) {
          ValidateRegistration.sendMobileData()
        }
      })
    }

    changeEmailLink.addEventListener('click', function(e) {
      e.preventDefault()
      ValidateRegistration.inputContainerSelector = '#email-input-group-container'
      ValidateRegistration.changeInput()
    })

    if (changeMobileLink) {
      changeMobileLink.addEventListener('click', function(e) {
        e.preventDefault()
        ValidateRegistration.inputContainerSelector = '#mobile-input-group-container'
        ValidateRegistration.retryTimerState = false;
        clearInterval(RetryTimerinterval)
        RetryTimerinterval = null;
        $('#retry-timer').html('')
        $('#retry-timer-container').hide()
        ValidateRegistration.changeInput()
      })
    }

    if (updateEmailLink) {
      updateEmailLink.addEventListener('click', function(e) {
        e.preventDefault()
        ValidateRegistration.inputContainerSelector = '#email-input-group-container'
        ValidateRegistration.validateLabel = 'Email Address'

        if ($(this).text() == 'Change') {
          $(this).text('Cancel')
          $("#registration_email").prop('readonly', false)
          $("#registration_email").removeClass('confirmed')
          ValidateRegistration.switchFieldStyle('resetInput')
        } else {
          document.querySelector('#registration_email').value = document.querySelector('#current_email').innerHTML
          ValidateRegistration.switchFieldStyle('showConfirmed')
          $(this).text('Change')
          $("#registration_email").prop('readonly', true)
          $("#registration_email").addClass('confirmed')
          $(ValidateRegistration.inputContainerSelector + ' .change-confirmed-note').hide()
        }
      })
    }

    if (updateMobileLink) {
      updateMobileLink.addEventListener('click', function(e) {
        e.preventDefault()
        ValidateRegistration.inputContainerSelector = '#mobile-input-group-container'
        ValidateRegistration.validateLabel = 'Mobile Number'

        if ($(this).text() == 'Change') {
          $(this).text('Cancel')
          $("#registration_mobile_layer").prop('readonly', false)
          $("#registration_mobile_layer").removeClass('confirmed')
          ValidateRegistration.switchFieldStyle('resetInput')
        } else {
          let value = document.querySelector('#current_mobile').innerHTML
          ValidateRegistration.resetMobileNumber(value)

          ValidateRegistration.switchFieldStyle('showConfirmed')
          $(this).text('Change')
          $("#registration_mobile_layer").prop('readonly', true)
          $("#registration_mobile_layer").addClass('confirmed')
          $(ValidateRegistration.inputContainerSelector + ' .change-confirmed-note').hide()
        }
      })
    }

    /**
     * On continue registration click button
     */
    if (continueButton) {
      continueButton.addEventListener('click', function (e) {
        e.preventDefault()
        ValidateRegistration.continueRegistration()
      })
    }
  },

  verifyEmailOTP: function () {
    let otp = $(ValidateRegistration.inputContainerSelector + ' .registration_otp_code').val()
    let formData = {
      'registration': {
        'email': ValidateRegistration.currentEmail,
        'otp_code': otp
      }
    }

    ValidateRegistration.validateLabel = 'Email address'
    ValidateRegistration.inputContainerSelector = '#email-input-group-container'
    ValidateRegistration.verifyOTP(formData)
  },

  verifyMobileOTP: function () {
    let otp = $(ValidateRegistration.inputContainerSelector + ' .registration_otp_code').val()
    let formData = {
      'registration': {
        'mobile': ValidateRegistration.currentMobile,
        'otp_code': otp
      }
    }

    ValidateRegistration.validateLabel = 'Mobile number'
    ValidateRegistration.inputContainerSelector = '#mobile-input-group-container'
    ValidateRegistration.verifyOTP(formData)
  },

  /**
   * Send data to backend to verify otp code
   */
  verifyOTP: function (formData) {
    let dataObj = {
      method: 'POST',
      url: '/registration-validation-verify',
      data: formData
    }

    let btnSelector = $('#verify_email_otp_button')
    if(formData['registration']['mobile']) {
      btnSelector = $('#verify_mobile_otp_button')
    }
    ValidateRegistration.showBtnSpinner(btnSelector)

    axios(dataObj)
      .then(response => {
        ValidateRegistration.resetBtnSpinner(btnSelector)
        if (response.status == 200 && response.data == true) {
          ValidateRegistration.switchFieldStyle('showConfirmed')

          if(formData['registration']['mobile']) {
            $("#registration_mobile_layer").prop('readonly', true)
            $("#registration_mobile_layer").addClass('confirmed')
            $("#valid-msg").hide()
          } else {
            $("#registration_email").prop('readonly', true)
            $("#registration_email").addClass('confirmed')
            $("#registration_mobile_layer").prop('disabled', false)
            $("#mobile-input-group-container .send-otp").prop('disabled', false)
          }
        } else if(formData['registration']['mobile']) {
          ValidateRegistration.setError('Invalid OTP, please use the OTP that is sent to your mobile ' + ValidateRegistration.currentMobile)
        } else {
          ValidateRegistration.setError('Invalid OTP, please use the OTP that is sent to your email ' + ValidateRegistration.currentEmail)
        }
      })
      .catch(error => console.error('catch', error))
    if ($('input[name="recaptcha_token"]').length) {
      grecaptcha.reset();
    }
  },

  setRetryTimer: (duration) => {
    let timer = duration;
    let elem = document.querySelector('#retry-timer')
    let elemContainer = $('#retry-timer-container')
    let retry_mobile =  document.querySelector('#retry-mobile')

    if (ValidateRegistration.retryTimerState === true) {
      clearInterval(RetryTimerinterval);
      RetryTimerinterval = null;
      elemContainer.hide()
      elem.innerHTML = ''
    }

    if (!RetryTimerinterval) {
      elemContainer.show()
      $('#resend_validate_mobile').hide()
      retry_mobile.innerText = ValidateRegistration.currentMobile
      RetryTimerinterval = setInterval(() => {
        ValidateRegistration.retryTimerState = true
        elem.textContent = parseInt(timer) + 's'; // set time

        if (--timer < 0) {
          clearInterval(RetryTimerinterval)
          RetryTimerinterval = null;
          elemContainer.hide()
          elem.innerHTML = '' // clear timer element
          $('#resend_validate_mobile').show()
        }
      }, 1000);
    }

  },

  setTimer: (duration) => {
    let timer = duration, minutes, seconds;
    let elem = document.querySelector(ValidateRegistration.inputContainerSelector + ' .otp-timer')
    let elemContainer = $(ValidateRegistration.inputContainerSelector + ' .otp-timer-container')

    if (ValidateRegistration.timerState === true) {
      clearInterval(OtpTimerinterval);
      OtpTimerinterval = null;
      elemContainer.hide()
      elem.innerHTML = ''
    }

    if (!OtpTimerinterval) {
      elemContainer.show()
      OtpTimerinterval = setInterval(() => {
        ValidateRegistration.timerState = true
        minutes = parseInt(timer / 60, 10);
        seconds = parseInt(timer % 60, 10);

        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        elem.textContent = minutes + ":" + seconds; // set time

        if (--timer < 0) {
          clearInterval(OtpTimerinterval)
          OtpTimerinterval = null;
          elemContainer.hide()
          elem.innerHTML = '' // clear timer element
          ValidateRegistration.switchFieldStyle('showInput')
        }
      }, 1000);
    }

  },

  /**
   * send data to backend
   */
  sendData: function (formData) {
    let dataObj = {
      method: 'POST',
      url: '/registration-validation',
      data: formData
    }

    ValidateRegistration.showBtnSpinner($(ValidateRegistration.inputContainerSelector + ' .validate-group button.send-otp'))

    if(formData['registration']['mobile']) {
      ValidateRegistration.showBtnSpinner($('#resend_validate_mobile'))
    } else {
      ValidateRegistration.showBtnSpinner($('#resend_validate_email'))
    }

    axios(dataObj)
      .then(response => {
        ValidateRegistration.resetBtnSpinner($(ValidateRegistration.inputContainerSelector + ' .validate-group button.send-otp'))

        if(formData['registration']['mobile']) {
          ValidateRegistration.resetBtnSpinner($('#resend_validate_mobile'))
          resendValidateMobileButton.querySelector('.resend_to').innerText = ValidateRegistration.currentMobile
        } else {
          ValidateRegistration.resetBtnSpinner($('#resend_validate_email'))
          resendValidateEmailButton.querySelector('.resend_to').innerText = ValidateRegistration.currentEmail
        }

        if (response.status == 200 && response.data.status == 'ok' && response.data.redirect_to) {
          let delay = 2000
          ValidateRegistration.setError(response.data.message)
          setTimeout(function () {
            document.location.href = response.data.redirect_to
          }, delay);
        } else if (response.status == 200 && response.data.status == 'ok') {
          let codeDate = new Date(response.data.time);
          let curDate = new Date(Date.now())
          let diff = codeDate.getTime() - curDate.getTime()
          ValidateRegistration.switchFieldStyle('showOTP')
          ValidateRegistration.setTimer(diff / 1000) // start timer

          if (response.data.retry_seconds) {
            ValidateRegistration.setRetryTimer(response.data.retry_seconds)
          }
        } else {
          ValidateRegistration.setError(response.data.message)
        }
      })
      .catch(error => console.error('catch', error))
  },

  /**
   * send email data to backend
   */
  sendEmailData: function () {
    let eventSlug = document.querySelector('#registration_email').getAttribute('data-event-slug')

    ValidateRegistration.validateLabel = 'Email address'
    ValidateRegistration.inputContainerSelector = '#email-input-group-container'
    ValidateRegistration.currentEmail = document.querySelector('#registration_email').value

    if (ValidateRegistration.validEmail(ValidateRegistration.currentEmail)) {
      let formData = { 'registration':
        { 'email': ValidateRegistration.currentEmail,
        'event_slug': eventSlug }
      }
      ValidateRegistration.sendData(formData)
    }
  },

  /**
   * send email data to backend
   */
  sendMobileData: function () {
    let eventSlug = document.querySelector('#registration_email').getAttribute('data-event-slug')

    ValidateRegistration.validateLabel = 'Mobile number'
    ValidateRegistration.inputContainerSelector = '#mobile-input-group-container'
    ValidateRegistration.currentEmail = document.querySelector('#registration_email').value
    ValidateRegistration.currentMobile = document.querySelector('#registration_mobile').value

    if (ValidateRegistration.validMobile(ValidateRegistration.currentMobile)) {
      let formData = {
        'registration': {
          'email': ValidateRegistration.currentEmail,
          'mobile': ValidateRegistration.currentMobile,
          'event_slug': eventSlug
        }
      }
      ValidateRegistration.sendData(formData)
    }
  },


  /**
   * continue with the rest of the registration form
   */
  continueRegistration: function () {
    if (!$("#registration_email").hasClass('confirmed')) {
      ValidateRegistration.setError('Please confirm your Email Address first.')
      return
    }

    if (validateMobileButton && !$("#registration_mobile_layer").hasClass('confirmed')) {
      ValidateRegistration.setError('Please confirm your mobile number first.')
      return
    }

    if (!ValidateRegistration.validatePassword()) {
      return
    }

    $("#registration_password").prop('readonly', true);
    $("#registration_password_confirmation").prop('readonly', true);
    $('.main-registration-form').show()
    $('#continue_registration').hide()
  },

  /**
   * Change style of this field
   * @param value String : command to switch style
   */
  switchFieldStyle: function (value) {
    if (value == 'showOTP') {
      $(ValidateRegistration.inputContainerSelector + ' .registration_otp_code').val('') // clear field from last saved data
      $(ValidateRegistration.inputContainerSelector + ' .validate-group').hide()
      $(ValidateRegistration.inputContainerSelector + ' .validate-otp-group').show()
      $(ValidateRegistration.inputContainerSelector + ' .validate_label').text('Enter OTP')
    } else if (value == 'showInput') {
      $(ValidateRegistration.inputContainerSelector + ' .validate-group').show()
      $(ValidateRegistration.inputContainerSelector + ' .validate-otp-group').hide()
      if ($(ValidateRegistration.inputContainerSelector + ' .validate-group span.confirmed-check').is(":hidden")) {
        $(ValidateRegistration.inputContainerSelector + ' .validate-group button.send-otp').show()
      }
      $(ValidateRegistration.inputContainerSelector + ' .validate_label').text(ValidateRegistration.validateLabel)
    } else if (value == 'showConfirmed') {
      $(ValidateRegistration.inputContainerSelector + ' .validate-group').show()
      $(ValidateRegistration.inputContainerSelector + ' .validate-otp-group').hide()
      $(ValidateRegistration.inputContainerSelector + ' .validate-group small.note').hide()
      $(ValidateRegistration.inputContainerSelector + ' .validate-group button.send-otp').hide()
      $(ValidateRegistration.inputContainerSelector + ' .validate-group button.send-otp').prop('disabled', true)
      $(ValidateRegistration.inputContainerSelector + ' .validate-group span.confirmed-check').show()
      $(ValidateRegistration.inputContainerSelector + ' .validate_label').text(ValidateRegistration.validateLabel)
      $(ValidateRegistration.inputContainerSelector + ' .change-confirmed-note').show()
    } else if (value == 'resetInput') {
      $(ValidateRegistration.inputContainerSelector + ' .validate-group span.confirmed-check').hide()
      $(ValidateRegistration.inputContainerSelector + ' .validate-group button.send-otp').show()
      $(ValidateRegistration.inputContainerSelector + ' .validate-group button.send-otp').prop('disabled', false)
      $(ValidateRegistration.inputContainerSelector + ' .validate-group small.note').show()
    }
  },

  changeInput: function () {
    ValidateRegistration.timerState = false;
    clearInterval(OtpTimerinterval)
    OtpTimerinterval = null;
    $(ValidateRegistration.inputContainerSelector + ' .otp-timer').html('')
    $(ValidateRegistration.inputContainerSelector + ' .otp-timer-container').hide()
    ValidateRegistration.switchFieldStyle('showInput')
  },

  /**
   * Validate email value
   * @param email Sting : email to validate
   * @return boolean true|false
   */
  validEmail: function (email) {
    let mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    if (email.match(mailFormat)) {
      return true
    }
    ValidateRegistration.setError('Email is not valid')
    return false
  },

  /**
   * Validate mobile value
   * @param mobile Sting : mobile to validate
   * @return boolean true|false
   */
  validMobile: function (mobile) {
    let mobileFormat = /^\+([0-9]{2,3}) ([0-9]{8,})$/
    if (mobile.match(mobileFormat)) {
      return true
    }
    ValidateRegistration.setError('Mobile number is not valid')
    return false
  },

  /**
   * Shows error if password is invalid
   */
  validatePassword: function () {
    if (!document.querySelector('#registration_password')) {
      return true
    }

    let password = document.querySelector('#registration_password').value
    let confirmPassword = document.querySelector('#registration_password_confirmation').value

    // check if password has correct length
    if (password.length < 8) {
      ValidateRegistration.setError('Password should be at least 8 characters long.')
      return false
    }
    // check if password has valid pattern
    let pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)./
    if (!password.match(pattern)) {
      ValidateRegistration.setError('Password must include at least one lowercase letter, one uppercase letter, and one digit.')
      return false
    }
    // check if password == confirm password
    if (password != confirmPassword) {
      ValidateRegistration.setError('Password must match Password confirmation.')
      return false
    }
    return true
  },

  /**
   * Set Error window
   * @param msg String : message of the error
   * @param timeout Int : number in miliseconds to hide modal window
   * @return void
   */
  setError: function (msg, timeout = 5000) {
    ValidateRegistration.createErrorElement(msg, 'alert-danger')

    window.setTimeout(function () {
      ValidateRegistration.clearError()
    }, timeout)
  },

  /**
   * Clear error window on clicking cross button
   * @return void
   */
  clearError: () => {
    let toRemove = document.querySelector('#notice')
    if (toRemove) {
      toRemove.parentNode.removeChild(toRemove);
    }
  },

  /**
   * Generate error modal window
   * @param msg Sting : message to be dispalyed on modal window
   * @param type String : type of message 'alert-danger'
   * @return void
   */
  createErrorElement: (msg, type) => {
    var body = document.querySelector('body')
    var notice = document.createElement('div')
    var alert = document.createElement('div')
    var icon = document.createElement('i')
    var button = document.createElement('button')
    var span = document.createElement('span')
    var text = document.createElement('span')

    notice.style.display = 'block'
    notice.setAttribute('id', 'notice')
    alert.setAttribute('role', 'alert')
    alert.className = 'alert ' + type + ' alert-dismissible fade show'
    icon.className = 'fas fa-exclamation-triangle'
    button.setAttribute('type', 'button')
    button.setAttribute('data-dismiss', 'alert')
    button.setAttribute('area-label', 'Close')
    button.className = 'close'
    span.setAttribute('aria-hidden', 'true')
    span.innerHTML = '&times;'
    text.innerHTML = msg

    body.appendChild(notice)
    notice.appendChild(alert)
    alert.appendChild(icon)
    alert.appendChild(text)
    alert.appendChild(button)
    button.appendChild(span)
  },

  showBtnSpinner: function (btnSelector) {
    let btnText = btnSelector.html()
    btnSelector.prop('disabled', true)
    btnSelector.html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>' +
      '</span> Loading...' +
      "<div class='d-none btn-text'>" + btnText + "</div>")
  },

  resetBtnSpinner: function (btnSelector) {
    let btnText = btnSelector.find('.d-none.btn-text').html()
    btnSelector.prop('disabled', false)
    btnSelector.html(btnText)
  },

  resetMobileNumber: function (value) {
    if (document.querySelector('#current_mobile')) {
      let input = document.querySelector('#registration_mobile_layer')
      let instance = window.intlTelInputGlobals.getInstance(input)
      input.classList.remove("error");
      document.querySelector("#error-msg").innerHTML = "";
      document.querySelector("#error-msg").classList.add("hide");
      document.querySelector("#valid-msg").classList.add("hide");
      instance.setNumber(value)
    }
  }

}

document.addEventListener("turbolinks:load", () => {
  /**
   * Run script only if form and validate email are present
   */
  if (RVForm && validateEmailButton) {
    ValidateRegistration.init()
  }
})
