import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/js/utils'
document.addEventListener("turbolinks:load", () => {
  // Mobile Country Code
  var input_mobile = document.querySelector("#registration_mobile_layer"),
  full_mobile = document.querySelector("#registration_mobile"),
  errorMsg = document.querySelector("#error-msg"),
  validMsg = document.querySelector("#valid-msg")
  if(!input_mobile){ input_mobile = full_mobile }

  // here, the index maps to the error code returned from getValidationError - see readme
  var errorMap = ["Invalid mobile number", "Invalid country code", "Number too short", "Number too long", "Invalid mobile number"];
  if(input_mobile){
    var supported_countries = $("#form-attr").data('country');

    // initialise plugin
    var iti = intlTelInput(input_mobile, {
      // preferredCountries: ["sg", "my"],
      separateDialCode: true,
      initialCountry: 'sg',
      onlyCountries: supported_countries
    });

    var reset = function() {
      input_mobile.classList.remove("error");
      if(errorMsg && validMsg){
        errorMsg.innerHTML = "";
        errorMsg.classList.add("hide");
        validMsg.classList.add("hide");
      }
    };

    // on blur: validate
    input_mobile.addEventListener('blur', function() {
      reset();
      if (input_mobile.value.trim()) {
        if (iti.isValidNumber() && iti.getSelectedCountryData().dialCode != undefined ) {
          validMsg.classList.remove("hide");
          input_mobile.setCustomValidity("");
          full_mobile.value = '+' + iti.getSelectedCountryData().dialCode + ' ' + iti.getNumber().slice(iti.getSelectedCountryData().dialCode.length+1);
        } else {
          input_mobile.classList.add("error");
          var errorCode = iti.getValidationError();
          if(errorCode < 0 || errorCode == undefined ){
            errorCode = 0
          }
          errorMsg.innerHTML = errorMap[errorCode];
          errorMsg.classList.remove("hide");
          input_mobile.setCustomValidity("Invalid mobile number");
          full_mobile.value = "";
        }
      }
    });
    // on keyup / change flag: reset
    input_mobile.addEventListener('change', reset);
    input_mobile.addEventListener('keyup', reset);
  }
})